<template>
  <div>

    <b-card no-body class="mb-0">

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>entries</label>
          </b-col>

          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="userFilter" label="name"
                :options="userOptions" class="w-100" :reduce="val => val.id" />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table ref="refLogListTable" class="position-relative" :items="fetchLogs" responsive :fields="tableColumns"
        primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc">

        <template #cell(originalData)="data">
          <json-viewer :value="getJsonFromString(data.item.originalData)"></json-viewer>
        </template>

        <template #cell(changedData)="data">
          <json-viewer :value="getJsonFromString(data.item.changedData)"></json-viewer>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination v-model="currentPage" :total-rows="totalLogs" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BAlert
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import axiosIns from "@/libs/axios";
import logStoreModule from "../logStoreModule";
import useLogsList from "./useLogsList";
import JsonViewer from "vue-json-viewer";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BAlert,
    vSelect,
    JsonViewer

  },
  methods: {
  },
  methods: {
    getJsonFromString(str) {
      try {
        return JSON.parse(str);
      } catch (e) {
        return str;
      }
    }

  },
  setup() {
    const APP_TEAM_STORE_MODULE_NAME = "app-log";
    const isAddNewLogSidebarActive = ref(false);

    if (!store.hasModule(APP_TEAM_STORE_MODULE_NAME))
      store.registerModule(APP_TEAM_STORE_MODULE_NAME, logStoreModule);

    const userOptions = ref([]);

    const fetchOptions = () => {
      store.dispatch('app/fetchOptionList', { user: true })
        .then((res) => {
          userOptions.value = res.data.users;
        })
    }

    onMounted(() => {
      fetchOptions();
    })


    const {
      fetchLogs,
      tableColumns,
      perPage,
      currentPage,
      totalLogs,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refLogListTable,
      ability,
      userFilter,
    } = useLogsList();

    return {
      isAddNewLogSidebarActive,
      fetchLogs,
      tableColumns,
      perPage,
      currentPage,
      totalLogs,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refLogListTable,
      ability,
      userFilter,
      userOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.badge {
  display: block;
  margin: 5px;
  max-width: 200px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
