import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function useLogsList(){

    const toast = useToast()

    const refLogListTable = ref(null)

    //userFilter
    const userFilter = ref(null)
    
    // Logs table contains column Log Code / Log Name / Status / Created at / Action
    const tableColumns = [
        { key: 'id', sortable: true },
        { key: 'content', label: 'ACTION'},
        { key: 'user.name' },
        { key: 'originalData'},
        { key: 'changedData'},
        { key: 'updated_at'}
    ]
    const perPage = ref(25)
    const totalLogs = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)

    const dataMeta = computed(() => {
        const localItemsCount = refLogListTable.value ? refLogListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1: 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalLogs.value,
        }
    })

    const refetchData = () =>{
        refLogListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, userFilter], () => {
        refetchData()
    })

    const fetchLogs = (ctx, callback) => {

        store.dispatch('app-log/fetchLogs', {
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            user_id: userFilter.value
        })
        .then(response => {
            const { logs, total } = response.data
            callback(logs)
            totalLogs.value = total
        })
        .catch(() => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error fetching logs list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                },
            })
        })
    }

    return {
        fetchLogs,
        tableColumns,
        perPage,
        currentPage,
        totalLogs,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refetchData,
        refLogListTable,
        ability,
        userFilter,
    }


}