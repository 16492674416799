import axiosIns from '@/libs/axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
      fetchLogs(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axiosIns.get('/admin_log/list', {params:queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
        })
      },
    },
  }